import React, { useEffect } from "react"
import styled from "styled-components"
import { device } from "../../components/shared/styles/breakpoints"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import { graphql } from "gatsby"
import CasestudyHeader from "../../components/Casestudy/CasestudyHeader"
import Duties from "../../components/Casestudy/Duties/Duties"
import YellowBox from "../../components/shared/YellowBox/YellowBox"
import Statistics from "../../components/Casestudy/Statistics"
import RealizationPost from "../../components/RealizationsPage/RealizationsPost"
import { H2 } from "../../components/shared/Typography/Typography"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Gallery from "../../components/Casestudy/Gallery/Gallery"
import MediaSlider from "../../components/Casestudy/Media/MediaSlider"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledYellowBox = styled(YellowBox)`
  max-width: 980px;
  @media ${device.lg} {
    margin: ${({ theme }) => `0 auto -${theme.spaces[96]} `};
  }
`
const StyledCasestudy = styled.div`
  padding-top: ${({ theme }) => theme.spaces[56]};
`

const RealizationPostWrapper = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
`

const StyledSeeNextContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
`

const StyledSeeNext = styled(H2)`
  margin: ${({ theme }) =>
    `${theme.spaces[64]} 0 ${theme.spaces[40]} ${theme.spaces[16]}`};
  @media ${device.xl} {
    margin: ${({ theme }) => `${theme.spaces[112]} 0  ${theme.spaces[40]}`};
  }
`

export default function Casestudy ({ data, pageContext }) {
  const { t } = useTranslation()
  const { contentfulRealizations } = data
  const { nextRealization } = pageContext

  const headerData = {
    title: contentfulRealizations.title,
    boldDescription: contentfulRealizations.boldDescription.boldDescription,
    regularDescription:
      contentfulRealizations.regularDescription.regularDescription,
    mainImage: contentfulRealizations.mainImage,
  }
  const shouldRenderStatistics = contentfulRealizations.statistics?.length > 0
  const shouldRenderMediaOpinions =
    contentfulRealizations.mediaOpinions?.length > 0

  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])

  return (
    <Layout>
      <Seo title={contentfulRealizations.title} />
      <StyledCasestudy>
        <CasestudyHeader headerData={headerData} />
        {contentfulRealizations.gallery && (
          <Gallery images={contentfulRealizations.gallery} data-aos="fade-in" />
        )}
        {contentfulRealizations.weCovered && (
          <Duties
            dutiesData={contentfulRealizations.weCovered}
            data-aos="fade-in"
          />
        )}
        {shouldRenderStatistics && (
          <Statistics
            statisticsData={contentfulRealizations.statistics}
            data-aos="fade-in"
          />
        )}
        {shouldRenderMediaOpinions && (
          <MediaSlider
            mediaOpinionsData={contentfulRealizations.mediaOpinions}
            data-aos="fade-in"
          />
        )}
        {nextRealization && (
          <>
            <StyledSeeNextContainer data-aos="fade-in">
              <StyledSeeNext>{t("Zobacz również")}</StyledSeeNext>
            </StyledSeeNextContainer>
            <RealizationPostWrapper>
              <RealizationPost
                title={nextRealization.title}
                text={nextRealization.boldDescription.boldDescription}
                linkUrl={`../${nextRealization.slug}`}
                linkText={t("Zobacz casestudy")}
                imgUrl={nextRealization.mainImage.file.url}
                imgAlt={nextRealization.mainImage.title}
                srcLogo={nextRealization.partnerImg.file.url}
                altLogo={nextRealization.partnerImg.title}
                data-aos="fade-in"
              />
            </RealizationPostWrapper>
          </>
        )}
        <StyledYellowBox data-aos="fade-in" />
      </StyledCasestudy>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulRealizations(
      slug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      title
      boldDescription {
        boldDescription
      }
      mainImage {
        file {
          url
        }
        title
      }
      regularDescription {
        regularDescription
      }
      weCovered
      gallery {
        ... on ContentfulAsset {
          id
          title
          file {
            url
          }
        }
      }
      statistics {
        ... on ContentfulRealizationStatistics {
          id
          blackText
          greySubText
          yellowText
        }
      }
      mediaOpinions {
        ... on ContentfulMediaOpinions {
          id
          author
          authorPosition
          text {
            text
          }
        }
      }
    }
  }
`
