import React, { useEffect, useRef, useState } from "react"
import { GalleryIconRight } from "../../shared/Icons/GalleryIconRight"
import { GalleryIconLeft } from "../../shared/Icons/GalleryIconLeft"
import styled from "styled-components"
import { Disclaimer } from "../../shared/Typography/Typography"
import { buttonStyles } from "../../shared/Button/Button"

const StyledContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
`
const StyledImageWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 100%;
  margin-bottom: ${({ theme }) => theme.spaces[16]};
`
const StyledSelectedImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  max-width: 1180px;
`
const StyledCarousel = styled.div`
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 80px;
    height: 88px;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }
  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
`
const StyledCarouselImages = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
  margin: ${({ theme }) => `${theme.spaces[24]} 0 ${theme.spaces[64]}`};
`

const StyledButtonNav = styled.button`
  ${buttonStyles};
  padding: 0;
  justify-content: center;
  border: none;
  width: 40px;
  height: 40px;
  position: absolute;
  top: ${({ theme }) => theme.spaces[24]};
  z-index: 2;
`
const StyledButtonLeft = styled(StyledButtonNav)`
  background-color: ${({ theme }) => theme.colors.lightGray};
  left: ${({ theme }) => theme.spaces[10]};
`

const StyledButtonRight = styled(StyledButtonNav)`
  background-color: ${({ theme }) => theme.colors.black};
  right: ${({ theme }) => theme.spaces[10]};
`

const StyledCarouselImage = styled.img`
  margin-right: ${({ theme }) => theme.spaces[10]};
  height: 88px;
  min-width: 163px;
  object-fit: cover;
  box-sizing: border-box;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    border: 2px solid ${theme.colors.yellow};
  `};
`
const StyledDisclaimer = styled(Disclaimer)`
  display: inline-block;
`
const DisclaimerWrapper = styled.div`
  text-align: center;
`

export default function Gallery({ images, ...props }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [selectedImage, setSelectedImage] = useState()
  const carouselItemsRef = useRef([])

  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        images.length
      )

      setSelectedImageIndex(0)
      setSelectedImage(images[0])
    }
  }, [images])

  const handleSelectedImageChange = newIdx => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx])
      setSelectedImageIndex(newIdx)
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          inline: "center",
          block: "nearest",
          behavior: "smooth",
        })
      }
    }
  }

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1
      if (newIdx >= images.length) {
        newIdx = 0
      }
      handleSelectedImageChange(newIdx)
    }
  }

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1
      if (newIdx < 0) {
        newIdx = images.length - 1
      }
      handleSelectedImageChange(newIdx)
    }
  }

  return (
    <StyledContainer {...props}>
      <StyledImageWrapper>
        <StyledSelectedImage src={selectedImage?.file.url} />
      </StyledImageWrapper>
      <DisclaimerWrapper>
        <StyledDisclaimer>{images[selectedImageIndex].title}</StyledDisclaimer>
      </DisclaimerWrapper>
      <StyledCarousel>
        <StyledCarouselImages>
          {images &&
            images.map((image, idx) => (
              <StyledCarouselImage
                onClick={() => handleSelectedImageChange(idx)}
                src={image.file.url}
                key={idx}
                isSelected={selectedImageIndex === idx}
                ref={el => (carouselItemsRef.current[idx] = el)}
              />
            ))}
        </StyledCarouselImages>
        <StyledButtonLeft onClick={handleLeftClick} color="">
          <GalleryIconLeft />
        </StyledButtonLeft>
        <StyledButtonRight onClick={handleRightClick}>
          <GalleryIconRight />
        </StyledButtonRight>
      </StyledCarousel>
    </StyledContainer>
  )
}
