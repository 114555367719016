import React from "react"
import styled from "styled-components"
import {
  H2,
  H3,
  H4,
  ParagraphRegular,
  ParagraphBold,
  Disclaimer,
} from "../shared/Typography/Typography"
import { device } from "../shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledStatistics = styled.div`
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};
  margin: 0 auto;
  max-width: 1180px;
`
const StyledTextWrapper = styled.div`
  max-width: 680px;
`
const StyledTitle = styled(H2)`
  margin: 0;
`
const StyledSubtitle = styled(H4)`
  margin: ${({ theme }) => `${theme.spaces[16]} 0`};
`
const StyledText = styled(ParagraphRegular)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[40]}`};
`
const StyledYellowText = styled(H2)`
  color: ${({ theme }) => theme.colors.yellow};
  margin: ${({ theme }) => `0 0 ${theme.spaces[8]}`};
`
const StyledStatisticExp = styled(ParagraphBold)`
  margin: 0;
`
const StyledDisclaimer = styled(Disclaimer)`
  display: block;
  margin-top: -2px;
  color: ${({ theme }) => theme.colors.gray};
`
const StyledStatsGrid = styled.div`
  @media ${device.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`
const StyledStatBox = styled.div`
  margin: ${({ theme }) => `0 0 ${theme.spaces[24]}`};
  @media ${device.md} {
    margin: ${({ theme }) => `0 0 ${theme.spaces[40]}`};
    max-width: 320px;
  }
  @media ${device.lg} {
    max-width: 300px;
  }
`

export default function Statistics({ statisticsData, ...props }) {
  const { t } = useTranslation()
  return (
    <StyledStatistics {...props}>
      <StyledTextWrapper>
        <StyledTitle>{t("Statystyki")}</StyledTitle>
        <StyledSubtitle>{t("StatisticsTitle")}</StyledSubtitle>
        <StyledText>{t("StatisticsText")}</StyledText>
      </StyledTextWrapper>
      <StyledStatsGrid>
        {statisticsData.map((el, key) => (
          <StyledStatBox key={key}>
            <StyledYellowText>{el.yellowText}</StyledYellowText>
            <StyledStatisticExp>{el.blackText}</StyledStatisticExp>
            {el.greySubText && (
              <StyledDisclaimer>{el.greySubText}</StyledDisclaimer>
            )}
          </StyledStatBox>
        ))}
      </StyledStatsGrid>
    </StyledStatistics>
  )
}
