import React from "react"
import styled from "styled-components"
import { SectionTitleBig } from "../../shared/Typography/Typography"

export const StyledDuty = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  padding: ${({ theme }) => `${theme.spaces[18]} ${theme.spaces[24]}`};
  align-items: center;
  max-width: 600px;
  border-left: 8px solid ${({ theme }) => theme.colors.yellow};
  box-sizing: border-box;
  min-height: 97px;
`
export const StyledFirstLine = styled(SectionTitleBig)`
  margin: 0;
`

export default function DutyBox({ duty }) {
  return (
    <StyledDuty>
      <StyledFirstLine>{duty}</StyledFirstLine>
    </StyledDuty>
  )
}
