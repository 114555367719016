import React from "react"
export function GalleryIconLeft() {
  return (
    <svg width="7" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.362 10a.996.996 0 0 1-.719-.305l-3.863-4a1 1 0 0 1 .013-1.402l4-4a.999.999 0 1 1 1.414 1.414L2.902 5.012l3.179 3.293A1 1 0 0 1 5.362 10Z"
        fill="#0E1E1F"
      />
    </svg>
  )
}
