import React from "react"
export function GalleryIconRight() {
  return (
    <svg width="7" height="10" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 10a.999.999 0 0 1-.707-1.707l3.305-3.305-3.18-3.293a1 1 0 0 1 1.44-1.39l3.861 4a1 1 0 0 1-.012 1.402l-4 4A.997.997 0 0 1 1.5 10Z"
        fill="#FFD013"
      />
    </svg>
  )
}
