import React from "react"
import styled from "styled-components"
import { ParagraphRegular, H2 } from "../../shared/Typography/Typography"
import { device } from "../../shared/styles/breakpoints"
import DutyBox from "./DutyBox"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledDutiesWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spaces[64]};
`
const StyledDutiesGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces[8]};
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};
  @media ${device.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spaces[32]};
  }
  @media ${device.lg} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spaces[20]};
  }
  @media ${device.xl} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${({ theme }) => theme.spaces[20]};
    padding: 0;
  }
`
const StyledTextWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.spaces[16]} ${theme.spaces[40]} `};
  @media ${device.xl} {
    padding: ${({ theme }) => `0 0 ${theme.spaces[40]} `};
  }
`
const StyledTitle = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`
const StyledText = styled(ParagraphRegular)`
  max-width: 560px;
`
export default function Duties({ dutiesData, ...props }) {
  const { t } = useTranslation()
  return (
    <StyledDutiesWrapper {...props}>
      <StyledTextWrapper>
        <StyledTitle>{t("Co zrobiliśmy?")}</StyledTitle>
        <StyledText>{t("dutiesText")}</StyledText>
      </StyledTextWrapper>
      <StyledDutiesGrid>
        {dutiesData.map((el, key) => (
          <DutyBox duty={el} key={key} />
        ))}
      </StyledDutiesGrid>
    </StyledDutiesWrapper>
  )
}
