import React from "react"
import styled from "styled-components"
import { device } from "../shared/styles/breakpoints"
import { H2, H3, ParagraphRegular } from "../shared/Typography/Typography"
import Icon from "../../images/realizations/casestudy/cornerIcon.png"
const StyledMiniature = styled.img`
  width: 100%;
  object-fit: cover;
  display: block;
  min-height: 411px;
`
const StyledSmallYellowBox = styled(H3)`
  background-color: ${({ theme }) => theme.colors.yellow};
  margin: ${({ theme }) => `-${theme.spaces[40]} auto 0 `};
  padding: ${({ theme }) =>
    `${theme.spaces[16]} ${theme.spaces[32]}  ${theme.spaces[16]} ${theme.spaces[32]} `};
  min-width: 343px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  @media ${device.sm} {
    min-width: 428px;
  }
  @media ${device.lg} {
    margin: ${({ theme }) => `-${theme.spaces[40]} 0 0 `};
  }
`
const StyledHeader = styled.div`
  max-width: 1180px;
  margin: ${({ theme }) => `0 auto  ${theme.spaces[64]}`};
  width: 100%;
`
const StyledImageWrapper = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spaces[52]};
  @media ${device.lg} {
    margin-bottom: ${({ theme }) => theme.spaces[64]};
  }
`
const StyledIconBox = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  align-items: center;
  justify-content: center;
  display: none;
  @media ${device.lg} {
    display: flex;
  }
`
const StyledTextWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};
  max-width: 760px;
  @media ${device.xl} {
    padding: 0;
  }
`
const StyledTitle = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`
const StyledText = styled(ParagraphRegular)`
  margin: 0;
`

export default function CasestudyHeader ({ headerData }) {
  const { title, boldDescription, regularDescription, mainImage } = headerData
  return (
    <StyledHeader>
      <StyledImageWrapper>
        <StyledMiniature src={mainImage.file.url} alt={mainImage.file.title}></StyledMiniature>
        <StyledIconBox>
          <img src={Icon} alt="Icon" />
        </StyledIconBox>
        <StyledSmallYellowBox>{title}</StyledSmallYellowBox>
      </StyledImageWrapper>
      <StyledTextWrapper>
        <StyledTitle>{boldDescription}</StyledTitle>
        <StyledText>{regularDescription}</StyledText>
      </StyledTextWrapper>
    </StyledHeader>
  )
}
