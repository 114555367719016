import React, { useState, useEffect, useCallback, useRef } from "react"
import styled from "styled-components"
import { debounce } from "lodash"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import {
  ParagraphBold,
  ParagraphRegular,
  H2,
  Disclaimer,
} from "../../shared/Typography/Typography"
import SwiperCore, { Navigation } from "swiper"
import { device } from "../../shared/styles/breakpoints"
import Button from "../../shared/Button/Button"
import { GalleryIconRight } from "../../shared/Icons/GalleryIconRight"
import { GalleryIconLeft } from "../../shared/Icons/GalleryIconLeft"
import { useTranslation } from "gatsby-plugin-react-i18next"

SwiperCore.use([Navigation])

const SlidesWrapper = styled.div`
  display: flex;
  position: relative;
  .media-swiper {
    padding-left: 16px;
    padding-bottom: 120px;
    padding-right: 16px;
    height: auto;
    max-width: 1180px;
    .swiper-slide {
      max-width: calc(100% - 16px);
      width: 280px;
    }
  }
`
const StyledMediaWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  padding-top: ${({ theme }) => theme.spaces[64]};
  margin-top: ${({ theme }) => theme.spaces[64]};

  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const SliderItem = styled.div`
  width: 100%;
  height: 347px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

const Caption = styled.div`
  display: block;
  width: 100%;
  height: 70px;
  line-height: 54px;
  box-sizing: border-box;
  transition: ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spaces[16]} ${theme.spaces[26]} ${theme.spaces[16]} ${theme.spaces[24]}`};
`

const Content = styled.div`
  padding: ${({ theme }) => theme.spaces[24]};
  flex: 1 0 auto;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.yellow};
`

const Name = styled(ParagraphBold)`
  margin: 0;
`

const Text = styled(ParagraphRegular)`
  margin: 0;
`
const StyledDisclaimer = styled(Disclaimer)`
  margin: 0;
`

const MediaTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.white};
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
`
const MediaText = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.colors.white};
`
const StyledTitleWrapper = styled.div`
  margin: ${({ theme }) => `0 ${theme.spaces[16]} ${theme.spaces[40]} `};
  max-width: 490px;
  @media ${device.md} {
    text-align: center;
    margin: ${({ theme }) => `0 auto ${theme.spaces[40]} `};
    max-width: 615px;
  }
  @media ${device.lg} {
    max-width: 770px;
  }
`

const StyledNavigationItem = styled(Button)`
  position: absolute;
  bottom: 0;
  width: 80px;
  height: 80px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${({ shouldRenderNavigation }) =>
    !shouldRenderNavigation &&
    `
    opacity: 0;
  `}
  > span {
    display: flex;
    align-items: center;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0;
    transition: inherit;
  }

  &.swiper-button-disabled {
    pointer-events: none;
    &:after {
      opacity: 0.5;
    }
  }
`

const PrevNavigation = styled(StyledNavigationItem)`
  left: 50%;
  transform: translate(-100%, 0);
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: none;
  &.swiper-button-disabled {
    pointer-events: none;
  }
  ${({ color, theme }) =>
    color === "primary" &&
    `
        color: ${theme.colors.black};
        background-color: ${theme.colors.midGray};
        fill: ${theme.colors.black};
        &:hover {
            background-color: ${theme.colors.lightGray};
        }
        &:focus {
            background-color: ${theme.colors.midGray};
        }
        &:active {
            background-color: ${theme.colors.darkGray};
        }
    `};
`

const NextNavigation = styled(StyledNavigationItem)`
  right: 50%;
  transform: translate(100%, 0);
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  ${({ color, theme }) =>
    color === "primary" &&
    `
        color: ${theme.colors.yellow};
        background-color: ${theme.colors.black};
        fill: ${theme.colors.yellow};
        &:hover {
            background-color: ${theme.colors.black300};
        }
        &:focus {
            background-color: ${theme.colors.black};
        }
        &:active {
            background-color: ${theme.colors.black700};
        }
    `};
`

const StyledNavWrapper = styled.div``

export default function MediaSlider({ mediaOpinionsData, ...props }) {
  const { t } = useTranslation()
  const [shouldRenderNavigation, setShouldRenderNavigation] = useState(true)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const sliderRef = useRef(null)
  const slideWidth = 280
  const slidesLength = mediaOpinionsData.length

  const handleResize = useCallback(
    debounce(() => {
      const sliderWidth = sliderRef.current.getBoundingClientRect().width
      const visibleSlides = Math.floor(sliderWidth / slideWidth)
      setShouldRenderNavigation(visibleSlides < slidesLength)
    }, 300),
    []
  )

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  return (
    <>
      <StyledMediaWrapper {...props}>
        <StyledTitleWrapper>
          <MediaTitle>{t("Media o projekcie")}</MediaTitle>
          <MediaText>{t("Media")}</MediaText>
        </StyledTitleWrapper>

        <SlidesWrapper>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            grabCursor={true}
            className="media-swiper"
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={swiper => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            ref={sliderRef}
          >
            {mediaOpinionsData.map((el, key) => (
              <SwiperSlide key={key}>
                {({ isActive }) => (
                  <SliderItem isActive={isActive}>
                    <Content>
                      <Text>{el.text.text}</Text>
                    </Content>
                    <Caption>
                      <Name>{el.author}</Name>
                      <StyledDisclaimer>{el.authorPosition}</StyledDisclaimer>
                    </Caption>
                  </SliderItem>
                )}
              </SwiperSlide>
            ))}

            <StyledNavWrapper>
              <PrevNavigation
                color="primary"
                ref={navigationPrevRef}
                shouldRenderNavigation={shouldRenderNavigation}
              >
                <GalleryIconLeft />
              </PrevNavigation>
              <NextNavigation
                color="primary"
                ref={navigationNextRef}
                shouldRenderNavigation={shouldRenderNavigation}
              >
                <GalleryIconRight />
              </NextNavigation>
            </StyledNavWrapper>
          </Swiper>
        </SlidesWrapper>
      </StyledMediaWrapper>
    </>
  )
}
