import React from "react"
import styled from "styled-components"
import { ParagraphRegular, H3 } from "../shared/Typography/Typography"
import { InternalLink } from "../shared/Link/Link"
import { device } from "../shared/styles/breakpoints"

const StyledTextBox = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) =>
    `${theme.spaces[40]} 0  ${theme.spaces[30]}`};
  @media ${device.lg} {
    position: absolute;
    z-index: 1;
    width: 380px;
    height: 304px;
    box-sizing: border-box;
    top: calc(50% - 152px);
    left: 0;
  }
  @media ${device.lg} {
    transform: translate(-100px);
  }
`
const StyledTextWrapper = styled.div`
  padding: ${({ theme }) => `0 ${theme.spaces[30]} 0 ${theme.spaces[40]}`};
  @media ${device.sm} {
    max-width: 500px;
  }
`

const StyledMiniature = styled.img`
  width: 100%;
  min-height: 303px;
  object-fit: cover;
  display: block;
  max-height: 320px;
  @media ${device.lg} {
    position: relative;
    max-height: 415px;
    max-width: 100%;
  }
`

const StyledTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `0 ${theme.spaces[20]}  ${theme.spaces[16]} ${theme.spaces[40]}`};
  margin: 0;
`

const StyledText = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`

const StyledLogoBox = styled.div`
  height: 80px;
  width: 180px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  position: relative;
  float: right;
  margin: ${({ theme }) => `-${theme.spaces[40]} 0  0`};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.lg} {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(40px);
    z-index: 1;
    float: none;
  }
`

const StyledRealizationPost = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spaces[80]};
  @media ${device.lg} {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 100px);
    margin-left: auto;
  }
  @media ${device.xl} {
    width: 100%;
  }
`
const StyledInternalLink = styled(InternalLink)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spaces[25]};
  color: ${({ theme }) => theme.colors.yellow};
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.spaces[4]};
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.yellow};
    transform: translateY(100%);
    transition: ${({ theme }) => theme.transition};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
    &:before {
      transform: translateY(0);
    }
  }
`

export default function RealizationPost ({
  title,
  text,
  imgUrl,
  imgAlt,
  linkUrl,
  linkText,
  srcLogo,
  altLogo,
  ...props
}) {
  return (
    <StyledRealizationPost {...props}>
      <StyledTextBox>
        <StyledTitle>{title}</StyledTitle>
        <StyledTextWrapper>
          <StyledText>{text}</StyledText>
          <StyledInternalLink
            to={linkUrl}
            sufixIcon
            color="primary"
            withTranslation={false}
          >
            {linkText}
          </StyledInternalLink>
        </StyledTextWrapper>
      </StyledTextBox>
      <StyledMiniature src={imgUrl} alt={imgAlt}></StyledMiniature>
      <StyledLogoBox>
        <img src={srcLogo} alt={altLogo} />
      </StyledLogoBox>
    </StyledRealizationPost>
  )
}
